import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/loading-spinner/accessibility",
  "title": "LoadingSpinner - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`The colour contrast between the loading spinner and its background must comply with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`AA Level contrast ratios`}</ExternalLink>{`. You can find more information about accessible colour combinations in `}<InternalLink href="/foundation/design-tokens/colour" mdxType="InternalLink">{`Colour design token documentation`}</InternalLink>{`.`}</li>
      <li parentName="ul">{`Multiple simultaneous loading spinners can be very confusing for assistive technologies. `}<strong parentName="li">{`HDS Loading spinner React component is built in a way that only reads one alert for screen readers`}</strong>{` - even if there were multiple on the screen. If you are unable to use this feature or it fits your needs poorly (e.g. your project does not use JavaScript), please consider using only one spinner at a time and giving that one spinner the most accurate possible description.`}</li>
      <li parentName="ul">{`HDS Loading spinner must be given labels to describe the loading event. Use `}<inlineCode parentName="li">{`loadingText`}</inlineCode>{` prop for description for loading and `}<inlineCode parentName="li">{`loadingFinishedText`}</inlineCode>{` prop for description for loading finishing.`}
        <ul parentName="li">
          <li parentName="ul">{`Descriptions should not be too detailed. You should use generic descriptions such as "`}<em parentName="li">{`The page content is loading`}</em>{`" and "`}<em parentName="li">{`The page content loading was finished`}</em>{`".`}</li>
          <li parentName="ul">{`If there are multiple spinners on the screen, they all must have identical descriptions. Remember that the description is read to screen readers only once.`}</li>
          <li parentName="ul">{`If the spinner also has a visual label, it can be hidden from assistive technologies to avoid description to be read twice.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      